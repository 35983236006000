import { injectGlobal } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  .grid-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    .grid-column-base {
      &.is-zindex-lv1 {
        position: relative;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
      }

      &.is-zindex-lv2 {
        position: relative;
        z-index: ${VARIABLES.Z_INDEXS.LV_2};
      }

      &.is-zindex-lv3 {
        position: relative;
        z-index: ${VARIABLES.Z_INDEXS.LV_3};
      }

      &.is-zindex-lv4 {
        position: relative;
        z-index: ${VARIABLES.Z_INDEXS.LV_4};
      }
    }

    /* Modifiers
    ------------------------------- */
    /* Media queries
    ------------------------------- */
  }
`;
