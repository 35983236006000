import {
  injectGlobal
} from 'styled-components'


injectGlobal`
  /* Home
  ============================================================ */
  .home {
    .XXXXX {
      /* Style */
    }
  }
`
