import { injectGlobal } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  .modal-base {
    /* Parent
    ------------------------------- */
    padding-left: 30px;
    padding-right: 30px;

    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-scroll {
      overflow: auto;
      height: 100vh;
      flex-wrap: wrap;
    }

    .modal-section-base {
      &.is-news-promotions-header {
        margin-right: -55px;
        margin-left: -55px;
      }
    }

    &.is-second-modal {
      z-index: ${VARIABLES.Z_INDEXS.LV_7};

      + .modal-overlay-base {
        z-index: ${VARIABLES.Z_INDEXS.LV_6};
      }
    }


    /* Media queries
    ------------------------------- */
  }
`;
