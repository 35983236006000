import {
  injectGlobal
} from 'styled-components'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'


injectGlobal`
  .text-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    /* Regular */
    &.is-regular-36 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_36};
    }

    &.is-regular-28 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_28};
    }

    &.is-regular-24 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_24};
    }

    &.is-regular-22 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_22};
    }

    &.is-regular-20 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_20};
    }

    &.is-regular-18 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_18};
    }

    &.is-regular-16 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_16};
    }

    &.is-regular-14 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
    }

    &.is-regular-12 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
    }

    &.is-regular-11 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_11};
    }

    &.is-regular-10 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_10};
    }

    &.is-regular-9 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_9};
    }

    &.is-regular-8 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_8};
    }

    &.is-regular-7 {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_7};
    }

    /* Medium */
    &.is-medium-36 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_36};
    }

    &.is-medium-28 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_28};
    }

    &.is-medium-24 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_24};
    }

    &.is-medium-22 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_22};
    }

    &.is-medium-20 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_20};
    }

    &.is-medium-18 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_18};
    }

    &.is-medium-16 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_16};
    }

    &.is-medium-14 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_14};
    }

    &.is-medium-12 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_12};
    }

    &.is-medium-11 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_11};
    }

    &.is-medium-10 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_10};
    }

    &.is-medium-9 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_9};
    }

    &.is-medium-8 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_8};
    }

    &.is-medium-7 {
      ${TYPOGRAPHYS.FIRST.MEDIUM.FS_7};
    }

    /* Bold */
    &.is-bold-48 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_48};
    }

    &.is-bold-36 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_36};
    }

    &.is-bold-28 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_28};
    }

    &.is-bold-24 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_24};
    }

    &.is-bold-22 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_22};
    }

    &.is-bold-20 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_20};
    }

    &.is-bold-18 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_18};
    }

    &.is-bold-16 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_16};
    }

    &.is-bold-14 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_14};
    }

    &.is-bold-12 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_12};
    }

    &.is-bold-11 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_11};
    }

    &.is-bold-10 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_10};
    }

    &.is-bold-9 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_9};
    }

    &.is-bold-8 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_8};
    }

    &.is-bold-7 {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_7};
    }

    /* Decoration */
    &.is-underline {
      text-decoration: underline;
    }

    /* Ellipsis */
    &.is-ellipsis-two-line {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &.is-break-all {
      word-break: break-all;
    }

    &.is-break-word {
      word-break: break-word;
    }

    &.is-position-absolute {
      position: absolute;
    }

    &.is-underline-white {
      line-height: 1;
      border-bottom: 1px solid ${VARIABLES.COLORS.WHITE};
    }

     /* Line height */
    &.is-lineheight-th {
      line-height: 1.8;
    }
    /* Media queries
    ------------------------------- */
  }
`
