import { injectGlobal } from "styled-components";

injectGlobal`
  .chart-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-modifier-name {}

    /* Media queries
    ------------------------------- */
  }
`;
