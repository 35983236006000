import { injectGlobal } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  .button-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-lightest-blue {
      background-color: ${VARIABLES.COLORS.BLUE_27};
    }

    &.is-light-blue {
      background-color: ${VARIABLES.COLORS.PRIMARY_1};
    }

    &.is-medium-blue {
      background-color: ${VARIABLES.COLORS.BLUE_21};
    }

    &.is-blue {
      background-color: ${VARIABLES.COLORS.PRIMARY_2};
    }

    &.is-light-red {
      background-color: ${VARIABLES.COLORS.RED_6};
    }

    &.is-red {
      background-color: ${VARIABLES.COLORS.RED_4};
    }

    &.is-green {
      background-color: ${VARIABLES.COLORS.GREEN_4};
    }

    &.is-light-green {
      background-color: ${VARIABLES.COLORS.GREEN_3};
    }

    &.is-brown {
      background-color: ${VARIABLES.COLORS.BROWN_2};
    }

    &.is-gray {
      background-color: ${VARIABLES.COLORS.GRAY_19};
    }

    &.is-gradient-blue {
      background: linear-gradient(180deg, ${VARIABLES.COLORS.GRADIENT_BLUE_2_START} 0%, ${VARIABLES.COLORS.GRADIENT_BLUE_2_END} 100%);
    }

    &.is-shadow {
      box-shadow: ${VARIABLES.COLORS.SHADOW_2};
    }

    &.is-text-light-blue {
      .text-base {
        color: ${VARIABLES.COLORS.PRIMARY_1};
      }
    }

    &.is-outline-lightgreen {
      border: 1px solid ${VARIABLES.COLORS.GREEN_3};
      background-color: ${VARIABLES.COLORS.WHITE};

      .text-base {
        color: ${VARIABLES.COLORS.GREEN_3};
      }
    }
    
    &.is-outline-blue {
      border: 1px solid ${VARIABLES.COLORS.PRIMARY_2};
      background-color: ${VARIABLES.COLORS.WHITE};

      .text-base {
        color: ${VARIABLES.COLORS.PRIMARY_2};
      }
    }

    &.is-outline-lightestblue {
      border: 1px solid ${VARIABLES.COLORS.BLUE_16};

      .text-base {
        color: ${VARIABLES.COLORS.PRIMARY_2};
      }
    }

    &.is-button-preview-lucky-draw {
      border-radius: 10px;
      height: 40px;
    }

    &.is-preview-campaign-view-detail {
      border-radius: 10px;
      height: 18px;
      padding: 10px;

      .text-base {
        font-size: 10px;
      }
    }

    &.is-gradient-blue {
      background: rgb(40,80,110);
      background: -moz-linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
      background: -webkit-linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
      background: linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
    }
    /* Media queries
    ------------------------------- */
  }
`;
