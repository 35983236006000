import { injectGlobal } from "styled-components";

injectGlobal`
  .image-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-flag-shadow {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
    }

    &.is-contain-image {
      .image-ratio-base {
        object-fit: contain;
      }
    }

    /* Media queries
    ------------------------------- */
  }
`;
