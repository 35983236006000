import { injectGlobal } from "styled-components";
import { default as TYPOGRAPHYS } from "./../../../themes/styles/bases/typographys";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  .shape-container-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-dropdown {
      > .shape-container-content-base {
        box-shadow: ${VARIABLES.COLORS.SHADOW_2};
      }
    }

    &.is-dropdown-cms-notification {
      max-height: 328px;
      overflow: auto;
      > .shape-container-content-base {
        box-shadow: ${VARIABLES.COLORS.SHADOW_2};
      }
    }

    &.is-product-information {
      width: 100%;
      max-width: 1050px;
    }

    &.search-control {
      .search-control-container {}

      .earch-control-group-left {}

      .search-control-partition {}

      .earch-control-group-right {}
    }

    .is-upload-image-control {
      position: relative;

      .shape-container-buttons {
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_2};
        top: 15px;
        right: 15px;
      }
    }

    &.is-tab-body {
      .shape-container-content-base {
        border-radius: 0 5px 5px 5px;
      }
    }

    &.is-gold-card-header {
      background-color: #D9BC72;
      justify-content: center;
      border-radius: 10px 10px 0 0;
    }

    &.is-gold-card-body {
      border-radius: 0 0 10px 10px;
      overflow: hidden;
    }

    &.is-card-read-term {
      background: url(${require("./../../../themes/images/contents/bg-preview-campaign.jpeg")}), lightgray no-repeat;
      background-position: center top;
      border-radius: 10px;
    }

    &.is-copy-clipboard {
      /* Tooltip box */
      &:before {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_10};
        pointer-events: none;
        content: 'Copied';
        transform: translateX(-50%);
        opacity: 0;
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        bottom: calc(100% + 8px);
        left: 50%;
        padding: 2px 10px 1px;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 3px;
        color: ${VARIABLES.COLORS.WHITE};
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      /* Tooltip arrow */
      &:after {
        pointer-events: none;
        content: ' ';
        transform: translateX(-50%);
        opacity: 0;
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        bottom: calc(100% - 2px);
        left: 50%;
        border-width: 5px 4px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
      }

      &:active:before,
      &:active:after {
        opacity: 1;
      }
    }

    /* Media queries
    ------------------------------- */
    &.search-control {
      .search-control-container {
        @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
          max-width: ${VARIABLES.BREAKPOINTS.BP_768};
          margin: auto;
        }

        > .grid-container-base {
          @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
            flex-direction: column;
          }
        }
      }

      .earch-control-group-left {}

      .search-control-partition {
        .shape-container-base,
        .shape-container-content-base {
          @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
            display: block;
            width: 100%;
            height: 1px;
          }
        }
      }

      .earch-control-group-right {}
    }
  }
`;
