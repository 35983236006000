// //////////////////////////////////////////////////////////////////////////////////////////////////
// ==================================================================================================
//
// Variables:
//
// * Factors
// * Website width
// * Colors
// * Font
// * Z indexs
// * Breakpoints
// * Transitions
// * Animation timings
// * Components
//   - Scrollbar
//
// ==================================================================================================
// //////////////////////////////////////////////////////////////////////////////////////////////////

// Factors (using in variables)
// ============================================================
const SPACING_FACTOR = 5
const GRID_SYSTEM = 12

export default {
  // Factors
  // ============================================================
  FACTORS: {
    SPACING: SPACING_FACTOR,
    GRID: GRID_SYSTEM
  },

  // Website width
  // ============================================================
  WEBSITE_WIDTH: 1440,

  // Colors
  // ============================================================
  COLORS: {
    // Base
    // ------------------------------
    // Transparent
    TRANSPARENT: 'transparent',
    // Black
    BLACK: '#000000',
    // White
    WHITE: '#FFFFFF',
    // Gray
    GRAY_1: '#F8F8F8',
    GRAY_2: '#F1F1F1',
    GRAY_3: '#EEEEEE',
    GRAY_4: '#EDEDEC',
    GRAY_5: '#E9E9F0',
    GRAY_6: '#E8E9EC',
    GRAY_7: '#E1E1E1',
    GRAY_8: '#DDDDDD',
    GRAY_9: '#D9D9D9',
    GRAY_10: '#CECECE',
    GRAY_11: '#CFCFCF',
    GRAY_12: '#CBCBCB',
    GRAY_13: '#8E95A1',
    GRAY_14: '#A3A6B4',
    GRAY_15: '#808495',
    GRAY_16: '#777777',
    GRAY_17: '#707070',
    GRAY_18: '#7A7A7A',
    GRAY_19: '#898989',
    GRAY_20: '#43425D',
    GRAY_21: '#4D4F5C',
    GRAY_22: '#4B4B4B',
    GRAY_23: '#373A3C',
    GRAY_24: '#D1D1D1',
    GRAY_25: '#525659',
    GRAY_26: '#ECECEC',
    GRAY_27: '#F8F8FD',
    GRAY_28: '#F7F7F7',
    GRAY_29: '#555',
    GRAY_30: '#474747',
    // Red
    RED_1: '#FFE8E8',
    RED_2: '#FF6565',
    RED_3: '#FF4141',
    RED_4: '#FF2929',
    RED_5: '#CC001E',
    RED_6: '#FF8A8A',
    // Green
    GREEN_1: '#5EE2A0',
    GREEN_2: '#20D959',
    GREEN_3: '#3CC480',
    GREEN_4: '#01B79E',
    GREEN_5: '#09842F',
    GREEN_6: '#449C2E',
    // Blue
    BLUE_1: '#F1F9FD',
    BLUE_2: '#EAF0F4',
    BLUE_3: '#EFF6FA',
    BLUE_4: '#F5FAFE',
    BLUE_5: '#F6F9FA',
    BLUE_6: '#EEF7FD',
    BLUE_7: '#E0F1FB',
    BLUE_8: '#E7F4FC',
    BLUE_9: '#E4F2FC',
    BLUE_10: '#EBF5FD',
    BLUE_11: '#D8EDFA',
    BLUE_12: '#DDEFFB',
    BLUE_13: '#CDE8F9',
    BLUE_14: '#C7E5F8',
    BLUE_15: '#CFDFE5',
    BLUE_16: '#C1D9EA',
    BLUE_17: '#D2EAF9',
    BLUE_18: '#54D8FF',
    BLUE_19: '#6CCCE2',
    BLUE_20: '#5FABDE',
    BLUE_21: '#328BC8',
    BLUE_22: '#196FAA',
    BLUE_23: '#006CB6',
    BLUE_24: '#004698',
    BLUE_25: '#F2F5F9',
    BLUE_26: '#3084BC',
    BLUE_27: '#EEF8FF',
    BLUE_28: '#A4CFEB',
    BLUE_29: '#52CAF5',
    // Light Blue
    LIGHT_BLUE_1: '#F2F5F9',
    LIGHT_BLUE_2: '#F8F8FD',
    LIGHT_BLUE_3: '#A4CFEB',
    LIGHT_BLUE_4: '#D7E8F4',
    // Brown
    BROWN_1: '#E1C5A0',
    BROWN_2: '#DEA85F',
    // Purple
    PURPLE_1: '#A3A1FB',
    // Orange
    ORANGE_1: '#EFC553',
    ORANGE_2: '#FF9429',
    // Gradient
    GRADIENT_BLACK_1_START: 'rgba(0, 0, 0, 0)',
    GRADIENT_BLACK_1_END: 'rgba(0, 0, 0, 0.65)',
    GRADIENT_GREEN_1_START: 'rgba(94, 226, 160, 1)',
    GRADIENT_GREEN_1_END: 'rgba(94, 226, 160, 0.5)',
    GRADIENT_PURPLE_1_START: 'rgba(163, 160, 251, 1)',
    GRADIENT_PURPLE_1_END: 'rgba(163, 160, 251, 0.5)',
    GRADIENT_BLUE_1_START: 'rgba(84, 261, 255, 1)',
    GRADIENT_BLUE_1_END: 'rgba(84, 261, 255, 0.5)',
    GRADIENT_BLUE_2_START: '#3E90C9',
    GRADIENT_BLUE_2_END: '#1A5C89',
    // Overlay
    OVERLAY_BLACK_1: 'rgba(0, 0, 0, 0.7)',
    OVERLAY_BLACK_2: 'rgba(0, 0, 0, 0.35)',
    OVERLAY_WHITE_1: 'rgba(255, 255, 255, 0.3)',
    OVERLAY_GREEN_1: 'rgba(219, 250, 229, 1)',
    // OVERLAY_GREEN_1: 'rgba(32, 217, 89, 0.24)',
    OVERLAY_BLUE_1: 'rgba(25, 111, 170, 0.54)',
    OVERLAY_BLUE_2: 'rgba(95, 171, 222, 0.2)',
    OVERLAY_BLUE_3: 'rgba(48, 132, 188, 0.3)',
    OVERLAY_BLUE_4: "rgba(212, 230, 242, 0.8)",
    OVERLAY_RED_1: 'rgba(255, 232, 232, 0.75)',
    // Shadow
    SHADOW_1: '3px 1px 6px rgba(0, 0, 0, 0.16)',
    SHADOW_2: '0 3px 6px rgba(0, 0, 0, 0.16)',
    SHADOW_3: '0 3px 10px rgba(0, 0, 0, 0.16)',
    SHADOW_4: '0 0 30px rgba(0, 0, 0, 0.15)',
    SHADOW_5: '0 1px 3px rgba(0, 0, 0, 0.05)',
    SHADOW_6: '0 0 10px rgba(0,0,0,0.1)',

    // Apply - bring 'Base' color create a new name for easy using
    // ------------------------------
    // Primary
    PRIMARY_1: '#5FABDE',
    PRIMARY_2: '#196FAA',
    // Status
    DISABLED: '#CBCBCB',
    ERROR: '#FF2929',
    SUCCESS: '#01B79E',
    // Scrollbar
    SCROLLBAR_DEFAULT: '#B5B5B5',
    SCROLLBAR_HOVER: '#858585',
    SCROLLBAR_ACTIVE: '#858585'
  },

  // Font
  // ============================================================
  FONT: {
    FAMILIES: {
      FIRST: {
        WEIGHTS: {
          REGULAR: 'Sarabun-Regular',
          MEDIUM: 'Sarabun-Medium',
          BOLD: 'Sarabun-Bold'
        },
        SIZES: {
          FS_48: 48, // Before > 54
          FS_36: 36, // Before > 48
          FS_28: 28, // Before > 36
          FS_24: 24, // Before > 30
          FS_22: 22, // Before > 28 ***
          FS_20: 20, // Before > 25
          FS_18: 18, // Before > 22 ***
          FS_16: 16, // Before > 20 ***
          FS_14: 14, // Before > 18 ***
          FS_12: 12, // Before > 16 ***
          FS_11: 11, // Before > 15
          FS_10: 10, // Before > 14 ***
          FS_9: 9,   // Before > 12
          FS_8: 8,   // Before > 11
          FS_7: 7    // Before > 10
        },
        LINE_HEIGHT_FACTOR: 1.3
      }
    }
  },

  // Zindexs
  // ============================================================
  Z_INDEXS: {
    LV_1: 1,
    LV_2: 9,
    LV_3: 10,
    LV_4: 11,
    LV_5: 99,
    LV_6: 100,
    LV_7: 101,
    LV_8: 999,
    LV_9: 1000,
    LV_10: 1001
  },

  // Breakpoints
  // ============================================================
  BREAKPOINTS: {
    // Mobile
    BP_320: '320px',
    BP_321: '321px',
    BP_359: '359px',
    BP_360: '360px',
    BP_361: '361px',
    BP_374: '374px',
    BP_375: '375px',
    BP_376: '376px',
    BP_413: '413px',
    BP_414: '414px',
    BP_415: '415px',

    // Phablet
    BP_479: '479px',
    BP_480: '480px',
    BP_481: '481px',
    BP_639: '639px',
    BP_640: '640px',
    BP_641: '641px',
    BP_666: '666px',
    BP_667: '667px',
    BP_668: '668px',
    BP_735: '735px',
    BP_736: '736px',
    BP_737: '737px',

    // Tablet
    BP_767: '767px',
    BP_768: '768px',
    BP_769: '769px',
    BP_811: '811px',
    BP_812: '812px',
    BP_813: '813px',
    BP_1023: '1023px',
    BP_1024: '1024px',
    BP_1025: '1025px',

    // Laptop
    BP_1279: '1279px',
    BP_1280: '1280px',
    BP_1281: '1281px',
    BP_1365: '1365px',
    BP_1366: '1366px',
    BP_1367: '1367px',
    BP_1439: '1439px',
    BP_1440: '1440px',
    BP_1441: '1441px',
    BP_1599: '1599px',
    BP_1600: '1600px',
    BP_1601: '1601px',

    // Desktop
    BP_1919: '1919px',
    BP_1920: '1920px',
    BP_1921: '1921px',
    BP_2559: '2559px',
    BP_2560: '2560px'
  },

  // Transitions
  // ============================================================
  TRANSITIONS: {
    DEFAULT: 'all 0.3s ease',
    TRANSITION_1_MS: 'all 0.1s ease'
  },

  // Animation timings
  // ============================================================
  ANIMATION_TIMINGS: {
    ELASTIC: 'cubic-bezier(.835, -.005, .06, 1)'
  },

  // Components
  // ============================================================

  // Button
  // ------------------------------
  BUTTON: {
    HEIGHTS: {
      H_20: 20,
      H_26: 26,
      H_32: 32
    },
    BORDER_RADIUSES: {
      BR_3: 3
    },
    PADDINGS: {
      P_10: 10,
      P_15: 15,
      P_20: 20,
      P_25: 25,
      P_35: 35
    },
    WIDTHS: {
      W_75: 75,
      W_100: 100,
      W_105: 105,
      W_120: 120,
      W_125: 125,
      W_135: 135,
      W_140: 140,
      W_145: 145,
      W_155: 155,
      W_160: 160,
      W_162: 162,
      W_165: 165,
      W_170: 170,
      W_180: 180,
      W_185: 185,
      W_200: 200,
      W_230: 230,
      W_255: 255,
      W_270: 270,
      W_275: 275,
      W_306: 306,
      W_382: 382,
    }
  },

  // Scrollbar
  // ------------------------------
  SCROLLBAR: {
    WIDTH: 8,
    HEIGHT: 8,
    BORDER_RADIUS: 8
  },

  // Field
  // ------------------------------
  FIELD: {
    HEIGHT: 32,
    PADDINGS: {
      TOP: 12,
      BOTTOM: 6,
      TOP_BLOCK: 12,
      BOTTOM_BLOCK: 7,
      HORIZONTAL: 15
    },
    BORDER_RADIUS: 3
  },

  // Modal
  // ------------------------------
  MODAL: {
    WIDTHS: {
      W_1245: 1245,
      W_1165: 1165,
      W_1145: 1145,
      W_1032: 1032,
      W_960: 960,
      W_770: 770,
      W_640: 640,
      W_570: 570,
      W_560: 560,
      W_530: 530,
      W_500: 500,
      W_480: 480,
      W_463: 463,
      W_445: 445,
    },

    CONTAINER: {
      C_1: {
        TRANSFORM_START: 'translateY(-30px)',
        TRANSFORM_END: 'translateY(0)',
        SPACING: 30
      }
    },

    HEADER: {
      H_1: {
        PADDING_TOP: 45,
        PADDING_BOTTOM: 20,
        PADDING_HORIZONTAL: 55,
        BORDER_BOTTOM_WIDTH: 1,
        BORDER_BOTTOM_STYLE: 'solid',
        BORDER_TOP_RIGHT_RADIUS: 10,
        BORDER_TOP_LEFT_RADIUS: 10
      }
    },

    BODY: {
      B_1: {
        PADDING_BOTTOM: 55,
        PADDING_HORIZONTAL: 55,
        PADDING_VERTICAL: 55
      }
    },

    FOOTER: {
      F_1: {
        PADDING_TOP: 35,
        PADDING_BOTTOM: 45,
        PADDING_HORIZONTAL: 55,
        BORDER_TOP_WIDTH: 1,
        BORDER_TOP_STYLE: 'solid',
        BORDER_BOTTOM_RIGHT_RADIUS: 10,
        BORDER_BOTTOM_LEFT_RADIUS: 10
      }
    }
  },

  // CMS Layout
  // ------------------------------
  CMS_LAYOUT: {
    TOPBAR: {
      HEIGHT: 65
    },

    SIDEBAR: {
      WIDTH: 205
    },

    CONTENT: {
    }
  },

  // Notification
  // ------------------------------
  NOTIFICATION: {
    WIDTHS: {
      DEFAULT: 535 // Not use 'W_' becuase this variable is global
    },
    BUTTON_CLOSE: {
      WIDTH: 75
    }
  },

  // Table
  // ------------------------------
  TABLE: {
    COLUMN_WIDTHS: {
      PHONE: 175,
      EMAIL: 200,
      POINT: 140,
      REWARD_POINT: 160,
      TRAIN: 100,
      SYNC: 100,
      THICKNESS: 120,
      COLOR: 100,
      AGING: 100,
      USED_THE_POINTS: 175,
      T1_SALES_TRANSACTION: 200,
      CHECKBOX: 50,
      LIFETIME: 110,
      STATUS: 150,
      STATUS_WARRANTY: 175,
      SELECTED_ITEM: 100,
      SELECTED_ITEM_ICON: 130,
      DATES: {
        CREATE: 175,
        CREATE_NOTIME: 140,
        EXPIRE: 275,
        DEFAULT: 110,
        GIFT_PICKUP: 185,
        SALES: 140,
        LAST_CHANGE: 200,
        ORDER_NOTIME: 100,
        PURCHASE: 135
      },
      IDS: {
        INDEX: 75,
        PERSON: 150,
        COIL: 100,
        REDEMPTION: 175,
        CUSTOMER: 150
      },
      NAMES: {
        FULL: 175,
        NICK: 125,
        COIL: 350,
        COIL_INNER: 348,
        COIL_DETAIL: 350,
        REWARD: 300,
        SALES_REPRESENTATIVE: 215,
        PRODUCT: 250,
        SUBJECT: 250,
        PROJECT: 300,
        PRODUCT_GROUP: 175,
        COIL_DESCRIPTION: 250
      },
      ACTIONS: {
        ACT_1: 215,
        ACT_2: 490,
        ACT_3: 110,
        ACT_4: 170,
        ACT_5: 150,
        ACT_6: 135,
        ACT_7: 125,
        ACT_8: 109,
        ACT_9: 140,
        ACT_10: 115
      },
      NUMBERS: {
        ORDER: 120,
        EARN_POINT: 150,
        REWARD: 145,
        COIL: 150,
        WARRANTY: 205,
        QUANTITY: 90
      },
      MEASUREMENTS: {
        ORDER: 120,
        LENGTH_BALANCE: 150,
        WEIGHT_BALANCE: 165,
        REMAINED_WEIGHT: 165,
        PURCHASE_LENGTH: 145,
        LENGTH: 200,
        WEIGHT: 200
      },
      ICONS: {
        SELECTED: 45
      },
      FIELDS: {
        ORDER: 135,
        UNIT: 145
      }
    },

    NEWS_PROMOTIONS: {
      COLUMN_WIDTHS: {
        PIN: 30,
        ORDER: 65,
        ID: 50,
        SUBJECT: 75,
        PUBLICATION_COUNTRY: 145,
        TYPE: 70,
        PUBLICATION_DATE: 125,
        EXPIRED_DATE: 110,
        STATUS: 55,
        BANNER: 50,
        COMMENT_MANAGEMENT: 90,
        LIKE_COUNT: 70,
        ACTION: 145,
      }
    },

    TERMS_CONDITIONS: {
      COLUMN_WIDTHS: {
        ID: 50,
        SUBJECT: 250,
        PUBLICATION_COUNTRY: 145,
        CREATION_DATE: 125,
        CHANGED_DATE: 125,
        STATUS: 125,
        ACTION: 145,
      }
    },

    REWARDS_CATALOG: {
      COLUMN_WIDTHS: {
        ID: 50,
        ITEM: 300,
        POINT: 100,
        STATUS: 150,
        ACTION: 145,
      }
    },

    REWARDS_CATALOG_CATEGORY: {
      COLUMN_WIDTHS: {
        ID: 50,
        CATEGORY: 175,
        STATUS: 150,
        ACTION: 325
      }
    },

    PRODUCTS: {
      COLUMN_WIDTHS: {
        ID: 50,
        ITEM: 300,
        STATUS: 150,
        ACTION: 220
      }
    },

    PRODUCTS_CATEGORY: {
      COLUMN_WIDTHS: {
        ID: 50,
        CATEGORY: 175,
        STATUS: 150,
        ACTION: 270,
      }
    },

    BSAD_LOCATION_INFORMATION: {
      COLUMN_WIDTHS: {
        ID: 50,
        NAME: 65,
        PHONE: 120,
        EMAIL: 65,
        AREA: 80,
        OPEN_DAY_HOURS: 135,
        STATUS: 55,
        CREATION_DATE: 90,
        LAST_CHANGE_DATE: 110,
        ACTION: 145
      }
    },

    PROJECT_REFERENCE: {
      COLUMN_WIDTHS: {
        ID: 50,
        NAME: 105,
        CREATION_DATE: 150,
        CHANGED_DATE: 150,
        STATUS: 150,
        ACTION: 145,
      }
    },

    AD_INFORMATION: {
      COLUMN_WIDTHS: {
        ID: 50,
        NAME: 200,
        COMPANY: 175,
        EMAIL: 175,
        BUSINESS_REGISTRATION: 235,
        MOBILE: 175,
        PHONE: 120,
        ACTION: 175
      }
    },

    KNOWLEDGE_BASE: {
      COLUMN_WIDTHS: {
        ID: 50,
        ITEM: 300,
        STATUS: 150,
        ACTION: 145,
      }
    },

    KNOWLEDGE_BASE_CATEGORY: {
      COLUMN_WIDTHS: {
        ID: 50,
        CATEGORY: 175,
        STATUS: 150,
        ACTION: 300,
      }
    },

    CONTACT_US: {
      COLUMN_WIDTHS: {
        ID: 50,
        SUBJECT: 250,
        CREATION_DATE: 150,
        CHANGED_DATE: 150,
        STATUS: 150,
        ACTION: 175
      }
    },

    MANUAL_FOR_USERS: {
      COLUMN_WIDTHS: {
        ID: 50,
        SUBJECT: 250,
        CREATION_DATE: 150,
        CHANGED_DATE: 150,
        STATUS: 150,
        ACTION: 175
      }
    },

    ACCOUNT_MANAGEMENT: {
      COLUMN_WIDTHS: {
        ID: 50,
        NAME: 250,
        EMAIL: 150,
        CREATION_DATE: 150,
        ACTION: 145,
      }
    },

    COMMENT_MANAGEMENT: {
      COLUMN_WIDTHS: {
        CHECKBOX: 30,
        ID: 50,
        NAME: 150,
        COMMENT: 250,
        DATE: 75,
        STATUS: 55,
        ACTION: 80,
      }
    },

    WARRANTY_TEMPLATE: {
      COLUMN_WIDTHS: {
        SELECT: 30,
        TEMPLATE_NAME: 125,
        PRODUCT_GROUP: 125,
        M3_PRODUCT_GROUP: 135,
        CREATION_DATE: 120,
        EDITED_DATE: 120,
        VIEW: 50,
        ACTION: 175,
      }
    },

    SEGMENT_MANAGEMENT: {
      COLUMN_WIDTHS: {
        ID: 50,
        SEGMENT_NAME: 200,
        SUB_SEGMANT_AMOUNT: 200,
        CREATION_DATE: 125,
        EDITED_DATE: 125,
        ACTION: 180,
        SUB_SEGMANT_NAME: 200,
        SUB_SEGMANT_DESCRIPTION: 200,
      }
    },

    M3_PRODUCT_GROUP: {
      COLUMN_WIDTHS: {
        ID: 50,
        M3_PRODUCT_GROUP: 150,
        TEMPLATE: 200,
        ACTION: 60,
      }
    },

    PRODUCT_GROUP_AND_M3_PRODUCT_GROUP_LISTS: {
      COLUMN_WIDTHS: {
        ID: 50,
        CODE: 160,
        SELECT: 30,
      }
    },

    WARRANTY_TEMPLATE_MAPPING: {
      COLUMN_WIDTHS: {
        SELECT: 90,
        M3_PRODUCT_GROUP: 150,
        PRODUCT_GROUP: 175,
        COLOR: 125,
        PRODUCT_DESCRIPTION: 125,
      }
    },

    WARRANTY_TEMPLATE_SELECT: {
      COLUMN_WIDTHS: {
        M3_PRODUCT_GROUP: 150,
        PRODUCT_GROUP: 250,
        VIEW: 50,
        SELECT: 50,
      }
    },

    DOCUMENTARY: {
      COLUMN_WIDTHS: {
        ID: 50,
        CATEGOTY: 105,
        CODE: 105,
        NAME: 135,
        M3_PRODUCT_GROUP: 140,
        CREATED_DATE: 115,
        EDITED_DATE: 115,
        STATUS: 65,
        VIEW: 50,
        ACTION: 185,
      }
    },

    DOCUMENTARY_M3_PRODUCT_GROUP_MAPPING: {
      COLUMN_WIDTHS: {
        ID: 50,
        CODE: 165,
        NAME: 320,
        ACTION: 90,
      }
    },

    SEGMENT_INFORMATION: {
      COLUMN_WIDTHS: {
        SEGMENT_NAME: 125,
        SUB_SEGMENT_NAME: 200,
        SEGMENT_DESCRIPTION: 145,
        SUB_SEGMENT_DESCRIPTION: 200,
      }
    },
  }
}
