import { injectGlobal } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  .section-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    .section-iframe {
      width: 100%;
      height: 100%;
    }

    /* Modifiers
    ------------------------------- */
    &.is-cursor {
      cursor: pointer;
    }

    &.is-relative {
      position: relative;
    }

    &.is-absolute {
      position: absolute;
    }

    &.is-overflow-hidden {
      overflow: hidden;
    }

    &.is-upload-avatar {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      right: 0;
      width: auto;
    }

    &.is-hint-password {
      position: relative;
    }

    &.is-modal-header-button {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      right: 55px;
      width: auto;
    }

    &.is-version-number {
      opacity: 0.35;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      bottom: 10px;
      left: 15px;

      &.is-version-number-right {
        right: 15px;
        left: auto;
      }

      &.is-version-number-sidebar {
        position: static;
        padding-left: 15px;
      }
    }

    &.is-cursor-default {
      cursor: default;
    }

    /* Media queries
    ------------------------------- */
    &.is-zindex-lv1 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
    }

    &.is-zindex-lv2 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
    }

    &.is-zindex-lv3 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_3};
    }

    &.is-zindex-lv4 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_4};
    }

    &.is-zindex-lv5 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_5};
    }

    &.is-zindex-lv6 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_6};
    }

    &.is-upload-avatar {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1365}) {
        position: static;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
      }
    }

    &.is-hint-password {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
        border-left: none;
      }

      &:after {
        @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
          content: ' ';
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 0;
          left: 45px;
          display: block;
          width: 1px;
          height: 100%;
          background-color: ${VARIABLES.COLORS.BLUE_15};
        }
      }
    }

    &.is-notification {
      max-height: 320px;
    }

    &.is-google-viewer,
    &.is-browser-viewer {
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    &.is-version-number {
      opacity: 0.35;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      bottom: 10px;
      left: 15px;

      &.is-version-number-right {
        right: 15px;
        left: auto;
      }
    }

    &.is-button-delete-segment-image {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 5px;
      right: 5px;
      width: auto;
    }

    &.is-warranty-page-lists {
      .warranty-page-thumbnail {
        margin: 0 0 30px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &.is-balloon-clipboard {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      width: auto;
      padding: 0 3px 2px 10px;
      background-color: ${VARIABLES.COLORS.PRIMARY_1};
      border-radius: 3px;

      &:after {
        content: ' ';
        pointer-events: none;
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &.is-balloon-no-arrow {
        &:after {
          display: none;
        }
      }

      &.is-balloon-clipboard-arrow-top,
      &.is-balloon-clipboard-arrow-bottom {
        &:after {
          margin-left: -6px;
          border-width: 6px 4px;
        }
      }

      &.is-balloon-clipboard-arrow-left,
      &.is-balloon-clipboard-arrow-right {
        &:after {
          margin-top: -4px;
          border-width: 4px 6px;
        }
      }

      &.is-balloon-clipboard-arrow-top {
        &:after {
          bottom: 100%;
          left: 50%;
          border-color: transparent transparent ${
            VARIABLES.COLORS.PRIMARY_1
          } transparent;
        }
      }

      &.is-balloon-clipboard-arrow-bottom {
        &:after {
          top: 100%;
          left: 50%;
          border-color: ${
            VARIABLES.COLORS.PRIMARY_1
          } transparent transparent transparent;
        }
      }

      &.is-balloon-clipboard-arrow-left {
        &:after {
          top: 50%;
          right: 100%;
          border-color: transparent ${
            VARIABLES.COLORS.PRIMARY_1
          } transparent transparent;
        }
      }

      &.is-balloon-clipboard-arrow-right {
        &:after {
          top: 50%;
          left: 100%;
          border-color: transparent transparent transparent ${
            VARIABLES.COLORS.PRIMARY_1
          };
        }
      }
    }

    &.is-balloon-border {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      border: 2px solid ${VARIABLES.COLORS.PRIMARY_1};
    }

    &.is-warranty-template-image-lists {
      > * {
        margin: 0 0 30px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &.is-bg-logo {
      background-image: url(${require("./../../../themes/images/shareds/bg-menu-logo.png")});
      background-repeat: no-repeat;
      background-position: right top;
      background-size: 370px 120px;
    }

    &.is-gold-card {
      display: flex;
      background-color: #D9BC72;
      border-radius: 85px;
      width: 170px;
      height: 170px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      right: -77px;
      bottom: -97px;
    }

    &.is-gradient-blue {
      background: rgb(40,80,110);
      background: -moz-linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
      background: -webkit-linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
      background: linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
    }
    
    &.is-break-all {
      word-break: break-all;
    }
    
    &.is-break-word {
      word-break: break-word;
    }
  }
`;
