import { injectGlobal } from "styled-components";
import { default as TYPOGRAPHYS } from "./../../../themes/styles/bases/typographys";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  .accordion-control-base {
    /* Animation
    ------------------------------- */
    @keyframes fade-in {
      0% {
          opacity: 0;
      }

      100% {
          opacity: 1;
      }
    }

    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Container */
    .accordion {
    }

    /* List */
    .accordion__item {

      &:last-child {}
    }

    /* Button */
    .accordion__title {

      &:focus {}

      /* Arrow */
      &:before,
      &:after {}

      &:before {}

      &:after {}

      /* Open content */
      &[aria-selected='true'] {
        &:before {}

        &:after {}
      }
    }

    /* Content */
    .accordion__body {
      &.accordion__body--hidden {}
    }

    /* Modifiers
    ------------------------------- */
    /* Dropdown */
    &.is-dropdown,
    &.is-dropdown-notification {
      /* Container */
      .accordion {
        /* List */
        .accordion__item {}

        /* Button */
        .accordion__title {
          padding-right: 32px;

          &:before {
            display: none;
          }

          &:after {
            transform: translate(0, -50%) rotate(0);
            right: 0;
            display: block;
            width: 13px;
            height: 8px;
            margin-top: -2px;
            background-image: url(${require("./../../../themes/images/icons/ic-arrow-dropdown-user.svg")});
            background-size: contain;
            background-repeat: no-repeat;
            background-color: ${VARIABLES.COLORS.TRANSPARENT};
            border-radius: 0;
          }

          /* Open content */
          &[aria-selected='true'] {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &:before {}

            &:after {
              transform: translate(0, -50%) rotate(180deg);
              background-image: url(${require("./../../../themes/images/icons/ic-arrow-dropdown-user.svg")});
            }
          }
        }

        /* Content */
        .accordion__body {
          transition: ${VARIABLES.TRANSITIONS.DEFAULT};
          transform: translateY(0);
          opacity: 1;
          right: -65px;
          left: auto;

          &.accordion__body--hidden {
            transform: translateY(15px);
            opacity: 0;
          }
        }
      }
    }

    /* Dropdown > Notification */
    &.is-dropdown-notification {
      /* Container */
      .accordion {
        /* List */
        .accordion__item {}

        /* Button */
        .accordion__title {
          padding-right: 0;

          &:before,
          &:after {
            display: none;
          }

          /* Open content */
          &[aria-selected='true'] {

          }
        }

        /* Content */
        .accordion__body {
          transition: ${VARIABLES.TRANSITIONS.DEFAULT};
          transform: translateY(15px);
          opacity: 1;
          right: -65px;
          left: auto;

          &.accordion__body--hidden {
            transform: translateY(30px);
            opacity: 0;
          }
        }
      }
    }

    /* Sidebar */
    &.is-menu-sidebar {

      min-height: calc(100% - 20px);
      /* Container */
      .accordion {
        border: none;
      }

      /* List */
      .accordion__item {
        border-bottom: none;

        &:last-child {}
      }

      /* Button */
      .accordion__title {
        padding-right: 0;

        &:focus {}

        /* Arrow */
        &:before,
        &:after {
          display: none;
        }

        &:before {}

        &:after {}

        /* Custom button */
        .accordion__button {
          transition: ${VARIABLES.TRANSITIONS.DEFAULT};
          padding: 12px 0 12px 5px;
          opacity: 0.7;

          /* State */
          &:hover,
          &.is-active {
            opacity: 1;
            background-color: ${VARIABLES.COLORS.PRIMARY_2};
          }
        }

        /* Open content (Collapsible) */
        &[aria-selected='true'] {
          .accordion__button {
            &.is-collapsible {
              opacity: 1;
              background-color: ${VARIABLES.COLORS.PRIMARY_1};

              &:hover {
                opacity: 1;
                background-color: ${VARIABLES.COLORS.PRIMARY_2};
              }

              .text-base {
                ${TYPOGRAPHYS.FIRST.BOLD.FS_12};
              }
            }
          }
        }


        &[aria-selected='true'] {
          /* opacity: 1;
          background-color: ${VARIABLES.COLORS.PRIMARY_2}; */

          &:before {}

          &:after {}
        }
      }

      /* Content */
      .accordion__body {
        padding-bottom: 5px;
        background-color: ${VARIABLES.COLORS.PRIMARY_1};

        &.accordion__body--hidden {}
      }

      /* Button submenu */
      .accordion__buttonsubmenu {
        cursor: pointer;
        transition: ${VARIABLES.TRANSITIONS.DEFAULT};

        &:hover,
        &.is-active {
          background-color: ${VARIABLES.COLORS.PRIMARY_2};

          .accordion__buttonsubmenu__arrow {
            opacity: 1;
          }
        }
      }

      .accordion__buttonsubmenu__arrow {
        opacity: 0;
      }
    }

    /* help */
    &.is-help {
      width: 100%;

      /* Container */
      .accordion {
        border: none;
      }

      /* List */
      .accordion__item {
        margin-bottom: 15px;
        border-bottom: none;

        &:last-child {
          margin-bottom: 0;
        }
      }

      /* Button */
      .accordion__title {
        padding: 13px 35px;
        background-color: ${VARIABLES.COLORS.BLUE_14};
        border-radius: 5px;

        &:focus {}

        /* Arrow */
        &:before,
        &:after {
        }

        &:before {
          display: none;
        }

        &:after {
          transform: translate(0, -50%) rotate(0);
          right: 35px;
          width: 24px;
          height: 24px;
          background-image: url(${require("./../../../themes/images/icons/ic-arrow-accordion-close.svg")});
          background-size: contain;
          background-color: ${VARIABLES.COLORS.TRANSPARENT};
          border-radius: 0;
        }

        /* Open content */
        &[aria-selected='true'] {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          &:before {}

          &:after {
            transform: translate(0, -50%) rotate(0);
            background-image: url(${require("./../../../themes/images/icons/ic-arrow-accordion-open.svg")});
          }
        }
      }

      /* Content */
      .accordion__body {
        padding: 30px 30px 15px;
        background-color: ${VARIABLES.COLORS.WHITE};
        border: 2px solid ${VARIABLES.COLORS.BLUE_14};
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        &.accordion__body--hidden {}
      }
    }

    /* Topic */
    &.is-topic {
      /* Container */
      .accordion {
        border: none;
      }

      /* List */
      .accordion__item {
        position: relative;
        margin-bottom: 5px;
        border-bottom: none;

        &:last-child {
          margin-bottom: 0;
        }
      }

      /* Button */
      .accordion__title {
        transition: ${VARIABLES.TRANSITIONS.DEFAULT};
        padding: 13px 150px 13px 35px;
        background-color: ${VARIABLES.COLORS.BLUE_25};
        line-height: 1;

        &:focus {}

        /* Arrow */
        &:before,
        &:after {}

        &:before {
          display: none;
        }

        &:after {
          transform: translate(0, -50%) rotate(0);
          right: 35px;
          width: 18px;
          height: 18px;
          background-image: url(${require("./../../../themes/images/icons/ic-arrow-accordion-circle-close.svg")});
          background-size: contain;
          background-color: ${VARIABLES.COLORS.TRANSPARENT};
          border-radius: 0;
        }

        .accordion__buttontopic {
          .text-base {
            color: ${VARIABLES.COLORS.BLACK};
          }
        }

        /* Open content */
        &[aria-selected='true'] {
          background-color: ${VARIABLES.COLORS.BLUE_26};
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          color: ${VARIABLES.COLORS.WHITE};

          &:before {}

          &:after {
            transform: translate(0, -50%) rotate(0);
            background-image: url(${require("./../../../themes/images/icons/ic-arrow-accordion-circle-open.svg")});
          }

          .accordion__buttontopic {
            .text-base {
              color: ${VARIABLES.COLORS.WHITE};
            }
          }

          + .accordion__buttontopic__action {
            .accordion__buttontopic__action__icon {
              &.is-accordion-open {
                display: inline-block;
              }

              &.is-accordion-close {
                display: none;
              }
            }
          }
        }
      }

      /* Content */
      .accordion__body {
        padding: 15px 35px;
        background-color: ${VARIABLES.COLORS.WHITE};

        &.accordion__body--hidden {}
      }

      /* Action */
      .accordion__buttontopic__action {
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 13px;
        right: 68px;
      }

      .accordion__buttontopic__action__icon {
        &.is-accordion-open {
          display: none;
        }

        &.is-accordion-close {
          display: inline-block;
        }
      }
    }

    /* Outline */
    &.is-outline {
      width: 100%;

      /* Container */
      .accordion {
        border: none;
      }

      /* List */
      .accordion__item {
        margin-bottom: 15px;
        border-bottom: none;

        &:last-child {
          margin-bottom: 0;
        }
      }

      /* Button */
      .accordion__title {
        padding: 10px 35px;
        background-color: ${VARIABLES.COLORS.WHITE};
        border: 1px solid ${VARIABLES.COLORS.PRIMARY_2};
        border-radius: 5px;

        &:focus {}

        /* Arrow */
        &:before,
        &:after {
        }

        &:before {
          display: none;
        }

        &:after {
          transform: translate(0, -50%) rotate(0);
          right: 35px;
          width: 20px;
          height: 20px;
          background-image: url(${require("./../../../themes/images/icons/ic-arrow-accordion-outline-close.svg")});
          background-size: contain;
          background-color: ${VARIABLES.COLORS.TRANSPARENT};
          border-radius: 0;
        }

        /* Open content */
        &[aria-selected='true'] {
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          &:before {}

          &:after {
            transform: translate(0, -50%) rotate(0);
            background-image: url(${require("./../../../themes/images/icons/ic-arrow-accordion-outline-open.svg")});
          }
        }
      }

      /* Content */
      .accordion__body {
        padding: 0 20px 20px;
        background-color: ${VARIABLES.COLORS.WHITE};
        border: 1px solid ${VARIABLES.COLORS.PRIMARY_2};
        border-top: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        &.accordion__body--hidden {}
      }
    }

    /* Media queries
    ------------------------------- */
  }
`;
