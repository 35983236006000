import {
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'


injectGlobal`
  /* Text editor content
  ============================================================ */
  .text-editor-content {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Default properties */
    * {}

    /* Global properties */
    h1, h2, h3, h4, h5, h6, p, u, s, sub, sup, span, li, a {
      font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
    }

    h1, h2, h3, h4, h5, h6, p, li {
      line-height: ${VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR};
    }

    strong, em {
      font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
    }

    /* Heading */
    h1 {
      font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_36 + `px`};
      line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_36 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
    }

    h2 {
      font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_28 + `px`};
      line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_28 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
    }

    h3 {
      font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_24 + `px`};
      line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_24 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
    }

    h4 {
      font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_20 + `px`};
      line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_20 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
    }

    h5 {
      font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_18 + `px`};
      line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_18 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
    }

    h6 {
      font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_16 + `px`};
      line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_16 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
    }

    /* Normal */
    p {
      font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 + `px`};
      line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
    }

    /* Text transform */
    strong,
    em,
    u,
    s {
      vertical-align: baseline;
    }

    /* Text sub & sup */
    sub {}

    sup {}

    /* Text color & background color */
    p {
      span {
        &[style*='color'] {}

        &[style*='background-color'] {}
      }
    }

    /* Text align */
    .ql-align-center {}

    .ql-align-right {}

    .ql-align-justify {}

    /* Order & Bullet list */
    ol,
    ul {
      li {
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
        list-style: inside;

        &:not(.ql-direction-rtl) {
          padding-left: 0;
        }

        &:before {
          display: none;
        }
      }
    }

    ol {
      padding-left: 0;
    }

    ol,
    ol li {
      list-style-type: decimal;
    }

    ol li:not(.ql-direction-rtl) {
      /* Text indent */
      &.ql-indent-1 {
        padding-left: 15px;
      }

      &.ql-indent-2 {
        padding-left: calc(15px * 2);
      }

      &.ql-indent-3 {
        padding-left: calc(15px * 3);
      }

      &.ql-indent-4 {
        padding-left: calc(15px * 4);
      }

      &.ql-indent-5 {
        padding-left: calc(15px * 5);
      }

      &.ql-indent-6 {
        padding-left: calc(15px * 6);
      }
    }

    ul {
      padding-left: 15px;
    }

    ul,
    ul li {
      /* Fix bullet isn't show full size. */
      /* list-style-type: disc; */
      list-style-type: none;
    }

    ul li {
      position: relative;

      /* Fix bullet isn't show full size. */
      &:before {
        content: ' ';
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 8px;
        left: -12px;
        display: block;
        width: 4px;
        height: 4px;
        background-color: ${VARIABLES.COLORS.BLACK};
        border-radius: 2px;
      }
    }

    ul li:not(.ql-direction-rtl) {
      &.ql-indent-1,
      &.ql-indent-2,
      &.ql-indent-3,
      &.ql-indent-4,
      &.ql-indent-5,
      &.ql-indent-6 {
        &:before {
          margin-left: -12px;
        }
      }

      /* Text indent */
      &.ql-indent-1 {
        padding-left: 15px;

        &:before {
          left: 15px;
        }
      }

      &.ql-indent-2 {
        padding-left: calc(15px * 2);

        &:before {
          left: calc(15px * 2);
        }
      }

      &.ql-indent-3 {
        padding-left: calc(15px * 3);

        &:before {
          left: calc(15px * 3);
        }
      }

      &.ql-indent-4 {
        padding-left: calc(15px * 4);

        &:before {
          left: calc(15px * 4);
        }
      }

      &.ql-indent-5 {
        padding-left: calc(15px * 5);

        &:before {
          left: calc(15px * 5);
        }
      }

      &.ql-indent-6 {
        padding-left: calc(15px * 6);

        &:before {
          left: calc(15px * 6);
        }
      }
    }

    /* Text indent */
    .ql-indent-1 {
      padding-left: 15px;
    }

    .ql-indent-2 {
      padding-left: calc(15px * 2);
    }

    .ql-indent-3 {
      padding-left: calc(15px * 3);
    }

    .ql-indent-4 {
      padding-left: calc(15px * 4);
    }

    .ql-indent-5 {
      padding-left: calc(15px * 5);
    }

    .ql-indent-6 {
      padding-left: calc(15px * 6);
    }

    /* Link */
    a {
      text-decoration: underline;
    }

    /* Image */
    img {}

    /* Video link(iframe) */
    .ql-video {
      width: 100%;
      max-width: 915px;
      height: 500px;
    }

    /* Modifiers
    ------------------------------- */
    &.is-mandatory {
      > * {
        &:last-child {
          display: inline-block;
          
          &:after {
            content: '•';
            position: relative;
            border-radius: 3px;
            color: ${VARIABLES.COLORS.ERROR};
            left: 5px;
            top: -3px;
            font-size: 18px;
          }
        }
      }
    }
    /* Media queries
    ------------------------------- */
  }

  /* Text editor content
  ============================================================ */
  .LinesEllipsis {
    &.is-table-info {
      padding-right: 5px;
      font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
      font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_12 + `px`};
      line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_12 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      color: ${VARIABLES.COLORS.GRAY_19};
      word-break: break-all;
    }
  }
`
