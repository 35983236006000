import { injectGlobal } from "styled-components";
import { default as TYPOGRAPHYS } from "./../../../themes/styles/bases/typographys";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  .field-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    .field-radio-base {
      .field-radio-icon-label-base {
        position: relative;
        top: 1px;
        line-height: 1;
      }
    }

    .field-checkbox-base {
      .field-checkbox-icon-label-base {
        position: relative;
        top: 1px;
        line-height: 1;
      }
    }

    .field-symbol-required {
      width: 12px;
      height: 12px;
      background-color: ${VARIABLES.COLORS.RED_4};
      border-radius: 50%;

      &.is-upload-avatar {
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 10px;
        right: 17px;
        border: 1px solid ${VARIABLES.COLORS.GRAY_12};
      }
    }

    /* Modifiers
    ------------------------------- */
    &.is-label-vertical,
    &.is-label-vertical-block {
      .field-container-base {
        display: block;
      }
    }

    &.is-label-vertical-block {
      .field-label-base {
        display: block;
        margin-bottom: 5px;
      }
    }

    &.is-focus {
      .field-label-base {
        color: ${VARIABLES.COLORS.PRIMARY_2};
      }

      .field-value-base,
      .field-file-name-base,
      .field-info-text-base {
        border-bottom-color: ${VARIABLES.COLORS.PRIMARY_2};
      }
    }

    &.is-required {
      .field-group-base {
        position: relative;

        &:after {
          content: ' ';
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 0;
          right: -10px;
          display: block;
          width: 6px;
          height: 6px;
          background-color: ${VARIABLES.COLORS.ERROR};
          border-radius: 50%;
        }
      }
    } 

    &.is-change-password {
      max-width: 475px;
    }

    &.is-fluid-file {
      width: 100%;

      .field-group-base,
      .field-file-image-base {
        width: 100%;
      }
    }

    &.is-pin {
      .field-checkbox-base {
        .field-checkbox-value-base {
          &:checked {
            + .field-checkbox-label-base {
              .field-checkbox-icon-base {
                &:before {
                  display: none;
                }
              }
            }
          }
        }

        .field-checkbox-icon-base {
          &:before {
            content: ' ';
            display: block;
            width: 13px;
            height: 13px;
            background-image: url(${require("./../../../themes/images/icons/ic-pin-inactive.svg")});
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }

    &.is-enable-field-icon-action {
      .field-icon-base {
        pointer-events: auto;
      }
    }

    &.is-disabled {
      &.is-enable-field-icon-action {
        .field-icon-base {
          pointer-events: none;
        }
      }

      .select-control {
        &.is-id-phone {
          .react-select__control {
            background-color: ${VARIABLES.COLORS.GRAY_1}
          }
        }
      }
    }

    &.is-upload-cover,
    &.is-upload-gallery {
      .field-file-base {
        &.is-avatar {
          align-items: flex-start;
        }
      }
    }

    &.is-warranty-page-name {
      .field-value-base {
        ${TYPOGRAPHYS.FIRST.MEDIUM.FS_18};
        line-height: 40px;
        height: 40px;
      }
    }

    &.is-switch-green {
      .field-switch-input-base {
        &:checked {
          + .field-switch-body-base {
            background-color: ${VARIABLES.COLORS.GREEN_3};
          }
        }
      }
    }

    &.is-disabled-checkbox {
      .field-checkbox-base {
        cursor: none;
        pointer-events: none;
      }
    }

    /* Media queries
    ------------------------------- */
  }

  .field-unit {
    position: relative;

    .field-unit-button-minus,
    .field-unit-button-plus {
      transform: translateY(-50%);
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 50%;
    }

    .field-unit-button-minus {
      left: 3px;
    }

    .field-unit-button-plus {
      right: 3px;
    }
  }
`;
