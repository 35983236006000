import { injectGlobal } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";


injectGlobal`
  .table-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    .table-row-base {
      border-radius: 3px;
    }

    .table-column-base {
      .table-sort-button-base {
        position: relative;
        right: auto;
        margin-left: 10px;
      }

      &.table-head-column {
        align-items: center;
      }

      &.table-body-column {
        &:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        &.is-action-field-text {
          padding-top: 5px;
          padding-bottom: 0;
        }

        &.is-action-field-checkbox {
          padding-top: 5px;
          padding-bottom: 0;
        }

        &.is-draft {
          .text-base {
            color: ${VARIABLES.COLORS.GRAY_12};
          }
        }
      }

      &.is-no-sort {
        .table-sort-button-base {
          display: none;
        }
      }

      &.is-selected {
        background-color: ${VARIABLES.COLORS.BLUE_14};
      }
    }

    .table-section-base {
      &.is-gradient-blue {
        margin-top: 10px;
        margin-bottom: 15px;
        background: rgb(199,229,248);
        background: -moz-linear-gradient(180deg, rgba(199,229,248,1) 0%, rgba(245,250,254,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(199,229,248,1) 0%, rgba(245,250,254,1) 100%);
        background: linear-gradient(180deg, rgba(199,229,248,1) 0%, rgba(245,250,254,1) 100%);
        border-radius: 3px;

        .table-column-base {
          border-bottom: 1px solid ${VARIABLES.COLORS.WHITE};
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.is-blue {
        background-color: ${VARIABLES.COLORS.BLUE_14};
        border-radius: 5px;

        .table-column-base {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.is-warranty-coil-list {
        flex: 1;
        padding-bottom: 30px;
        padding-left: ${VARIABLES.TABLE.COLUMN_WIDTHS.SELECTED_ITEM + `px`};
        border-top: 1px solid ${VARIABLES.COLORS.GRAY_8};
      }

      &.is-warranty-coil-detail {
        width: 100%;
        margin-top: -5px;
      }
    }

    .table-row-base {
      &.table-row-collapsible {
        flex-wrap: wrap;

        > .table-row-base {
          width: 100%;
        }
      }
    }

    .table-info {
      &.is-phone {
        position: relative;
        top: -2px;
      }

      &.is-flag {
        position: relative;
        top: -3px;
      }
    }

    /* Modifiers
    ------------------------------- */
    &.is-fluid {
      width: 100%;
    }

    &.is-striped {
      .table-section-base {
        &:first-child {
          margin-bottom: 5px;
        }
      }

      .table-row-base {
        &:nth-child(even) {
          .table-body-column {
            background-color: ${VARIABLES.COLORS.GRAY_1};

            &.is-selected {
              background-color: ${VARIABLES.COLORS.BLUE_14};
            }
          }

          .table-section {
            &.is-warranty-coil-list {
              .table-row-base {
                &:nth-child(odd) {
                  .table-body-column {
                    background-color: ${VARIABLES.COLORS.WHITE};
                  }
                }

                &:nth-child(even) {
                  .table-body-column {
                    background-color: ${VARIABLES.COLORS.GRAY_1};
                  }
                }
              }
            }
          }
        }
      }

      .table-section {
        &.is-warranty-coil-list {
          .table-row-base {
            &:nth-child(even) {
              .table-section {
                &.is-warranty-coil-detail {
                  background-color: ${VARIABLES.COLORS.GRAY_1};
                }
              }
            }
          }
        }
      }
    }

    &.is-action,
    &.is-action-checkbox {
      .table-body-column {
        padding-top: 7px;
        padding-bottom: 5px;
      }
    }

    &.is-action {
      .table-body-column {
        &:last-child {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    &.is-action-checkbox {
      .table-row-base {
        margin-bottom: 1px;
      }

      .table-body-column {
        &:last-child {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    &.is-action-switch {
      .table-body-column {
        padding-top: 7px;
        padding-bottom: 3px;

        &:last-child {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        &.is-switch-toggle {
          padding-top: 5px;
          padding-bottom: 0;
        }
      }
    }

    &.is-field-text,
    &.is-list-of-rewards {
      .table-body-column {
        padding-top: 7px;
        padding-bottom: 4px;
      }
    }

    &.is-field-text {
      .table-body-column {
        &:last-child {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    &.is-list-of-rewards {
      .table-body-column {
        &:nth-last-child(2) {
          /* padding-top: 6px;
          padding-bottom: 6px; */
          padding-top: 4px;
          padding-bottom: 0;
        }
      }
    }

    &.is-product-quantity {
      .table-body-column {
        padding-top: 7px;
        padding-bottom: 6px;

        &:first-child {
          padding-top: 5px;
          padding-bottom: 4px;
        }
      }
    }

    &.is-customer-profile {
      .table-row-base {
        .table-column-base {
          &.is-body-column {
            align-items: center;
            
            .react-select__menu-list {
              max-height: 80px;
            }
          }
        }
      }
    }
    
    &.is-customer-profile-last-above {
      .table-row-base {
        .table-column-base {
          &.is-body-column {
            align-items: center;
            
            .react-select__menu-list {
              max-height: 80px;
            }
          }
        }

        &:nth-last-child(1), 
        &:nth-last-child(2) {
          .table-column-base {
            &.is-body-column {
              .react-select__menu {
                top: auto;
                bottom: 100%;
              }
            }
          }
        }
      }
    }
    
    &.min-h-300 {
      min-height: 300px
    }

    /* Media queries
    ------------------------------- */
  }
`;
